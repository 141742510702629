import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from "../../context/CartContext";

import { FaCartPlus } from "react-icons/fa";

export function Card(props) {

  const navigate = useNavigate();
  const { addProducToCart } = useCart();

  const {title, titleparams, id, preco_venda, capa_img, category} = props.product

  function handleGoToBookWithCodigo(tituloparams, id) {
    navigate(`/livros/${tituloparams}`, {
      state: {
        code: id
      }
    })
  }

  function addProductAndGoToCart(id) {
    addProducToCart(id, capa_img, title, preco_venda, titleparams, category);
    navigate(`/cart`, {
      state: {
        category: category
      }
    });
  }

  function price(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  return (
    <div className="book">
      <p className="title-book fntrbt">{title}</p>
      <div className="box-img-book">
        <img className="img-book" src={capa_img} alt={title} onClick={() => handleGoToBookWithCodigo(titleparams, id)}/>
      </div>
      {/* <p className="historico">{historico}</p> */}
      
      <div className="price-book">
        <p>{price(preco_venda)}</p>
      </div>

      <div className="btn-acao-card">
        
        <button className="btn btn-success" style={{backgroundColor:'#666', borderColor:'#666'}}
         onClick={() => handleGoToBookWithCodigo(titleparams, id)}>
          <strong>
            + Detalhes
          </strong> 
        </button>

        <button className="btn btn-success"
          onClick={() => addProductAndGoToCart(id)}> 
          <strong>
           <FaCartPlus/> COMPRAR
          </strong> 
        </button>

      </div>
    </div>
  )
  
}
