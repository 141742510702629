import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap/';
//import api from '../../services/api';
import { database } from '../../services/firebase';
import { FaUserTie } from "react-icons/fa";

import * as Yup from 'yup';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';

import { useAuth } from "../../context/AuthContext";
import { NavBar } from '../../components/Navbar';

export function CreateAccount(){
  
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [msgsuccess, setMsgSucess] = useState(false);
  const { signup, currentUser } = useAuth();

  async function handleCreateuser(data, {reset}){

    try{
      setLoading(true);
      const schema = Yup.object().shape({
        email: Yup.string().required("* obrigatório"),
        whatsapp: Yup.string().required("* obrigatório"),
        password: Yup.string().required('* obrigatório'),
        passwordconfirmation: Yup.string().oneOf([Yup.ref('password'), null], '* a senha não é a mesma')
      });
      await schema.validate(data, {
        abortEarly:false,
      })

      const {email, password, whatsapp} = data;
      const newClient = await signup(email, password);
      const newUClientBd = database.ref(`users/${newClient.user.uid}/`);

      await newUClientBd.set({ 
        uid: newClient?.user?.uid,
        email: newClient?.user?.email,
        whatsapp,
        superUser: false,
      }); 

    formRef.current.setErrors({});
    setMsgSucess(true);
    setLoading(false);

    }catch(err){
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(currentUser){
        navigate("/cart");
      }
    }, 500);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser]);


  function handleGoToLogin(){
    navigate("/login");
  }



  return(
    <>
    <NavBar />

    <Container>

      <Row>
        <Col sm={4}></Col>
        <Col sm={4}>
        <Row className="formlogin font-face-bs" style={{border:'1px solid #ddd', padding:30, borderRadius:10, marginTop:50}}>
      {!msgsuccess ? 
        <div style={{padding:50, borderRadius:10}}>
          <Row>  
            <Col md={12} style={{textAlign: 'center'}}>
              {/* Crie sua Conta */}
            </Col>        
          </Row>
          <Row>
          <Col sm={12}>
            <Form onSubmit={handleCreateuser} ref={formRef}>   
                <Input name="email" placeholder="seu email" label="E-mail"/>
                <Input name="whatsapp" placeholder="seu whatsapp" label="WhatsApp"/>
                <Input name="password" placeholder="sua senha" label="senha" type="password"/>
                <Input name="passwordconfirmation" placeholder="confirme sua senha" label="confirme sua senha" type="password"/>
                <p></p>
                <button disabled={loading} type="submit" className="btn btn-success" 
                style={{width:'100%', fontWeight:'bold', color:'#fff'}}>
                  <FaUserTie/> CRIAR CONTA
                </button>
            </Form>
          </Col>
          </Row>
          <Row>
            <Col sm={12} style={{padding:20, textAlign: 'center'}}>
              <p style={{cursor: 'pointer'}} onClick={() => handleGoToLogin()}>Já tem cadastro? <strong>LOGIN</strong></p>
            </Col>
          </Row>
      </div>
       :      
       <div style={{padding:50, borderRadius:10, display: 'flex', alignItems: 'center', justifyContent:'center'}}>
          <h3 style={{cursor: 'pointer'}} onClick={() => handleGoToLogin()}>Sua conta foi criada com sucesso. Faça Login aqui</h3>
       </div>
        }
    </Row>

        </Col>
        <Col sm={4}></Col>
      </Row>


    </Container>


    
  </>   
  );
}