import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from '@unform/core';

const ReactDropzoneInput = ({ name }) => {
  const inputRef = useRef(null);

  const {fieldName, registerField, defaultValue=[]} = useField(name);

  const [acceptedFiles, setAcceptedFiles] = useState(defaultValue);
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop: onDropAcceptedFiles => {
      if (inputRef.current) {
        inputRef.current.acceptedFiles = onDropAcceptedFiles;
        setAcceptedFiles(onDropAcceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    }
  });

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    justifyContent:'center',
    overflow: 'hidden'
  };
  
  const img = {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const thumbs = acceptedFiles?.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="..."
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => acceptedFiles?.forEach(file => URL.revokeObjectURL(file.preview));
  }, [acceptedFiles]);


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.acceptedFiles || [];
      },
      clearValue: (ref) => {
        ref.acceptedFiles = [];
        setAcceptedFiles([]);
      },
      setValue: (ref, value) => {
        ref.acceptedFiles = value;
        setAcceptedFiles(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <div {...getRootProps()} onClick={() => inputRef.current?.click()} className="dropzone font-bariol">
      <input {...getInputProps()} accept="image/*" ref={inputRef} />
      {acceptedFiles?.length !== 0 && (
        <div style={{textAlign: 'center'}}>
          {acceptedFiles?.map(file => (
            <p key={file.name}>
              {/* • {file.name} */}
            </p>
          ))}
        </div>
      )}
      <div className="thumbs">{thumbs}</div>
      {isDragActive ? (
        <p>Para CARREGAR uma imagem <br/> arraste para dentro do quadro.</p>
      ) : (
        <p>Para CARREGAR uma imagem <br/> arraste para dentro do quadro.</p>
      )}
    </div>
  );
};

export default ReactDropzoneInput;