import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core';

export default function InputMask({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <> 
    {label && <label htmlFor={fieldName}>{label} 
    {error && <span className="error" style={{color:"#f00"}}> {error}</span>}
    </label>}
    <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
    
    </>

  );
};