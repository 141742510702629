import React, {useState, useEffect} from 'react';
import { Container, Row, Col }from 'react-bootstrap';
import { useNavigate}  from 'react-router-dom';

import { database } from '../../services/firebase';
import { useAuth } from "../../context/AuthContext";

export function Orders() {
  const { usuario } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let t = setTimeout(() => {
      if(!usuario){
        navigate("/livros");
      }
    }, 10);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [usuario]);

  const [orders, setOrders] = useState([]);
  const [orderById, setOrderById] = useState([]);

  useEffect(() => {  
    const ordersRef = database.ref(`/orders/`);
      ordersRef.on('value', (snapshot) => {      
        const pedidos = snapshot.val();
        const ordersList = [];
        for (let id in pedidos){
          ordersList.push({ id, ...pedidos[id]});
        }
        setOrders(ordersList);
      });
      return () => {ordersRef.off('value');}
  }, []);

  //console.log(orders);

  orders.sort(function (a, b) {
    return (b.pedido > a.pedido) ? 1 : ((a.pedido > b.pedido) ? -1 : 0);
  });

  useEffect(() => { 
    const ordersFiltered = orders.filter(order => order.userId === usuario.uid);
    setOrderById(ordersFiltered)
    // eslint-disable-next-line 
  },[usuario, orders]);

  function handleConverter(valor){
     const converter = valor?.toLocaleString('pt-br', {minimumFractionDigits: 2}) || "";
      return converter;
  }

  async function handleUpdateOrderPendente(id){
    const orderUpdateRef = database.ref(`/orders/${id}`);
    await orderUpdateRef.update({
      status:"Pendente"
    });
  }

  async function handleUpdateOrder(id){
    const orderUpdateRef = database.ref(`/orders/${id}`);
    await orderUpdateRef.update({
      status:"Enviado"
    });
  }

  async function handleRemoveOrder(id){
    const orderUpdateRef = database.ref(`/orders/${id}`);
    await orderUpdateRef.update({
      status:"Excluído"
    });    
  }

  return(
      <Container>  
        <Row>
          <Col md={12}>
          {usuario?.superUser ? <h1>Pedidos ADM</h1> : <h1>Meus Pedidos</h1>}
          </Col>
        </Row>
        <Row>

          {usuario?.superUser ? 
            <Col md={12}>
              {orders?.map(order => (
                <Row className="order" style={{marginTop:20, padding:30, borderRadius:10}} key={order.id}>
                    <Col sm={2}>
                      <strong>Pedido: </strong>{order.pedido}
                        <br/>
                      <strong>Status: </strong>{order.status}
                      {usuario?.superUser && 
                      <>
                        <button onClick={() => handleUpdateOrderPendente(order.id)}>PENDENTE</button>
                        <button onClick={() => handleUpdateOrder(order.id)}>ENVIADO</button>
                        <button onClick={() => handleRemoveOrder(order.id)}>REMOVER</button>
                      </>
                      }
                    </Col>
                    <Col sm={3}>                    
                      <strong>Data:</strong> {order.date}<br/>
                      <strong>Email:</strong> {order.email}
                      <p></p>
                    </Col>
                    <Col sm={4}>
                      <strong>Nome:</strong> {order.name}<br/>
                      <strong>CPF:</strong> {order.cpf}
                    </Col>
                    <Col sm={3}>
                      <strong>Tel:</strong> {order.telefone} <br/>
                      <strong>WhatsApp:</strong> {order.whatsapp}            
                    </Col>
                  <hr/>
                  <Col sm={12}>
                    <strong>ENDEREÇO PARA ENTREGA</strong>
                    <p></p>
                      <Row>
                        <Col sm={6}>
                        <strong>Endereço: </strong>{order.address.logradouro}, {order.address.numero} <strong>Comp.</strong> {order.address.complemento}
                        </Col>
                        <Col sm={6}>
                        <strong>Bairro:  </strong>{order.address.bairro}, {order.address.cidade} - {order.address.estado} - <strong>CEP:</strong> {order.address.cep}
                        </Col>                      
                      </Row>
                  </Col>
                  <p></p>
                  <hr/>
                  <strong>ITENS DO PEDIDO</strong>
                  <p></p>
                  <Col sm={12}>
                    {order?.itens.map(item => (
                      <Row key={item.id} className="linhaitem">
                        <Col sm={3}>
                          <strong>{item?.title}</strong> 
                        </Col>
                        <Col sm={2}>
                          <strong>R$:{handleConverter(item?.preco_venda)}</strong>/unid.
                        </Col>
                        <Col sm={1}>
                          <strong>Qtd:</strong> {item?.qtd}
                        </Col>
                        <Col sm={2}>
                          <strong>Total:</strong> R$:{handleConverter(item?.qtd * item?.preco_venda)}
                        </Col>
                      </Row>
                    ))}
                    </Col>
                    <p><br/><br/></p>
                    <Col sm={6}>
                    </Col>
                    <Col sm={2}>
                      <strong>Total: </strong>R$ {handleConverter(order.total)}
                    </Col>
                    <Col sm={2}>
                      <strong>Envio: </strong>R$ {handleConverter(order.frete)}
                    </Col>
                    <Col sm={2}>
                      <strong>SubTotal: R$ {order.valortotal}</strong>
                    </Col>
                </Row>
              ))}
            </Col>
            :
            <Col md={12}>
              {orderById.length > 1 ? orderById?.map(order => (
                <Row className="order" style={{marginTop:20, padding:30, borderRadius:10}} key={order.id}>
                    <Col sm={2}>
                      <strong>Pedido: </strong>{order.pedido}
                        <br/>
                      <strong>Status: </strong>{order.status}
                      {usuario?.superUser && 
                      <>
                        <button onClick={() => handleUpdateOrderPendente(order.id)}>PENDENTE</button>
                        <button onClick={() => handleUpdateOrder(order.id)}>ENVIADO</button>
                        <button onClick={() => handleRemoveOrder(order.id)}>REMOVER</button>
                      </>
                      }
                    </Col>
                    <Col sm={3}>
                      <strong>Data:</strong> {order.date}<br/>
                      <strong>Email:</strong> {order.email}
                      <p></p>
                    </Col>
                    <Col sm={4}>
                      <strong>Nome:</strong> {order.name}<br/>
                      <strong>CPF:</strong> {order.cpf}
                    </Col>
                    <Col sm={3}>
                      <strong>Tel:</strong> {order.telefone}<br/>
                      <strong>WhatsApp:</strong> {order.whatsapp}            
                    </Col>
                  <hr/>
                  <Col sm={12}>
                    <strong>ENDEREÇO PARA ENTREGA</strong>
                    <p></p>
                      <Row>
                        <Col sm={6}>
                        <strong>Endereço: </strong>{order.address.logradouro}, {order.address.numero} <strong>Comp.</strong> {order.address.complemento}
                        </Col>
                        <Col sm={6}>
                        <strong>Bairro:  </strong>{order.address.bairro}, {order.address.cidade} - {order.address.estado} - <strong>CEP:</strong> {order.address.cep}
                        </Col>                      
                      </Row>
                  </Col>
                  <p></p>
                  <hr/>
                  <strong>ITENS DO PEDIDO</strong>
                  <p></p>
                  <Col sm={12}>
                    {order?.itens.map(item => (
                      <Row key={item.id} className="linhaitem">
                        <Col sm={3}>
                          <strong>{item?.title}</strong> 
                        </Col>
                        <Col sm={2}>
                          <strong>R$:{handleConverter(item?.preco_venda)}</strong>/unid.
                        </Col>
                        <Col sm={1}>
                          <strong>Qtd:</strong> {item?.qtd}
                        </Col>
                        <Col sm={2}>
                          <strong>Total:</strong> R$:{handleConverter(item?.qtd * item?.preco_venda)}
                        </Col>
                      </Row>
                    ))}
                    </Col>
                    <p><br/><br/></p>
                    <Col sm={6}>
                    </Col>
                    <Col sm={2}>
                      <strong>Total: </strong>R$ {handleConverter(order.total)}
                    </Col>
                    <Col sm={2}>
                      <strong>Envio: </strong>R$ {handleConverter(order.frete)}
                    </Col>
                    <Col sm={2}>
                      <strong>SubTotal: R$ {order.valortotal}</strong>
                    </Col>
                </Row>
              )): "Você ainda não tem pedidos."}
            </Col>          
          }

        </Row>
      </Container>
  )
}