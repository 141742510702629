import React, { useState, useEffect } from 'react'
import { Container, Row, Col }from 'react-bootstrap';

import api from '../../services/api';

import { NavBar } from '../../components/Navbar';
import { Card } from '../../components/Card';

export function BooksPage() {
  const [books, setBooks] = useState([]);
  const [selectBooks, setSelectBooks] = useState([]);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;
    async function getCategoriesSql() {
      const response = await api.get('/categories');
      if (isSubscribed) {
        setCategories(response.data);
        setLoading(false);
      }
    };
    async function getBooksSql() {
      const response = await api.get('/livros');
      if (isSubscribed) {
        setBooks(response.data);
        setLoading(false);
      }
    };
    getCategoriesSql();    
    getBooksSql();
    return () => isSubscribed = false
  }, []);
  
  books.sort(function (a, b) {
    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
  });

  useEffect(() => {
    if(category !== null){
      const selects = books.filter(item => item.category === category)
      setSelectBooks(selects);
    }
  // eslint-disable-next-line
  }, [category])

  return (
    <>
      <NavBar />           
      <Container className="home-page">
        <Row>
          <Col sm={12} className="categories">
          {categories?.map((category) => 
            <button key={category.id} onClick={()=>{setCategory(category.value)}}>
              {category.name}
            </button>
          )}
          </Col>
        </Row>
        <Row>
          <Col className="contentBooks">
            {loading ? 
            <div className="loading"> <h2>Carregando...</h2> </div> :
            <>
              {category !== null ? 
                selectBooks?.map((book, index) => (
                  <Card key={index} product={book}/>
                )):
                books?.map((book, index) => (
                  <Card key={index} product={book}/>
                ))}
            </>
            }
          </Col>
        </Row>
      </Container>    
    </>
  );
}