import React, {} from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Container }from 'react-bootstrap';
import { NavBar } from '../../components/Navbar';
import { Cart } from '../../components/Cart'

export function CartPage() {

  return(
      <>
        <NavBar />         
        <Container className="home-page">
          <Cart/>
        </Container>
      </>  
  );
}
