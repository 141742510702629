import React, {useState, useEffect, useRef} from 'react'
import { Container, Row, Col, Modal }from 'react-bootstrap';
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext"

import * as Yup from 'yup';
import { Form as Formulario } from '@unform/web';
import Input from '../../components/Form/input';
import InputMask from '../../components/Form/inputMask';
import DropzoneInput from "../../components/Form/dropzone";
import api from '../../services/api';

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import uuid from 'react-uuid';
import {storage, database} from '../../services/firebase';

import { NavBar } from '../../components/Navbar';
import { MiniCard } from '../../components/MiniCard';

import { Worker, Viewer, ProgressBar } from '@react-pdf-viewer/core';

import { FaBook } from "react-icons/fa";

//import axeeate from '../../pdf/livros/axeeate.pdf';

export function BookPage() {
  // eslint-disable-next-line 
  const {id} = useParams();
  const navigate = useNavigate();

  const { admin, usuario } = useAuth();
  const { addProducToCart, removeProductToCart, productsCart = []} = useCart();
  const formRef = useRef(null);
  const formPermission = useRef(null); 

  const location = useLocation();

  const [codigo, setCodigo] = useState('');
  const [books, setBooks] = useState([]);
  const [book, setBook] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msgsucess, setMsgSucess] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    let isSubscribed = true
    async function getBooksSql() {
      const response = await api.get(`/livros/`);
      if (isSubscribed) {
        setBooks(response.data);
        setLoading(false);
      }        
    };
    getBooksSql();    
    return () => isSubscribed = false
  }, []);

  useEffect(() => {
    setCodigo(location?.state?.code);   
  }, [location]);

  useEffect(() => {
    let isSubscribed = true
    async function getBookSql() {
      const response = await api.get(`/livros/${codigo}`);
      if (isSubscribed) {
        setBook(response.data)
        setLoading(false);
      }        
    };
    getBookSql();    
    return () => isSubscribed = false
  }, [codigo, loading]);

  setTimeout(() => {
    formRef.current?.setData(book[0]);
  }, 500)

  books?.sort(function (a, b) {
    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
  });

  function price(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  async function handleSubmit(data, {reset}) {
    const novoid = uuid();
    const newImage = storage.ref(`capas/${novoid}`);

    setMsgSucess('');
    setLoading(true);

    const {titleparams, title, historico, release, pretexto, preco_venda, author, capa_img ,stock ,category, files} = data;

    await api.put(`/livros/${codigo}`, {
      titleparams, title, historico, release, pretexto, preco_venda,author, capa_img, stock, category,
    })
    .then((response) => {
      if(response.status === 200){
        setMsgSucess('Livro Atualizado com Sucesso!')
        setLoading(false);
        setTimeout(() => {
          setMsgSucess('')
          navigate("/livros");
        },5000)
      } else return response.json();
    }).catch(err => {
      setMsgSucess(err);
      setLoading(false);      
    });

    if(files[0]){
      setLoading(true);
      await newImage.put(files[0]);
      await newImage.getDownloadURL().then((url) => {    
        api.put(`/livros/${codigo}`,{
          capa_img:url
        });
      });
      setLoading(false);
      navigate("/livros");
    }

    //reset();
  }

  async function handleRemoveBook(id){
    confirmAlert({
      title: `Remover Livro?`,
      buttons: [
        {
          label: "SIM",
          onClick: async () =>{
              setLoading(true);
              await api.delete(`/livros/${id}`).then((response) => { 
                if(response.status === 200){
                setMsgSucess('Livro Removido com Sucesso!')
                setLoading(false);
                setTimeout(() => {
                  setMsgSucess('')
                  navigate("/livros");
                },500)
              } else return response.json();
            });
          } 
        },
        {
          label: "NÃO"
        }
      ]
    })
  }

  function handleGoToCart(category){
    navigate("/cart", {
      state: {
        category: category
      }
    });
  }

  function handleAbrirModal() {
    setModalShow(true);
  }



  async function handleCreatePermission(data, {reset}){
    const {title} = book[0] || [];
    const novoid = uuid();
    const hoje = new Intl.DateTimeFormat('pt-BR').format(new Date());

    const newPermission = database.ref(`readers/${novoid}`);
    
    try{
      const schema = Yup.object().shape({
      name: Yup.string()
      .required("*"),
      email: Yup.string().email()
      .required("*"),
      whatsapp: Yup.string()
      .required("*"),
      });

    await schema.validate(data, {
      abortEarly:false,
    })

    const {name, email, whatsapp} = data;

    await newPermission.set({
      livro:title,
      name,
      email,
      whatsapp,
      date:hoje,
    })
    formPermission.current.setErrors({});
    setPermission(true);


   }catch(err){
    if(err instanceof Yup.ValidationError){
      const errorMessages = {};
      err.inner.forEach(error => {
        errorMessages[error.path] = error.message;
      })
      formPermission.current.setErrors(errorMessages);

    }
  }
}
  
  function ModalVertical(props) {
    const {title, titleparams} = book[0] || [];
    const [msg, setMsg] =useState("");

    const renderError = (error) => {
      switch (error.name) {
          case 'UnknownErrorException':
            setMsg("Falhou ao carregar o Arquivo");
            break;
          case 'InvalidPDFException':
            setMsg("The document is invalid or corrupted");
            break;
          case 'MissingPDFException':
            setMsg("The document is missing");
            break;
          case 'UnexpectedResponseException':
            setMsg("Unexpected server response");
            break;            
          default:
            setMsg("Falha no carregamento do Arquivo");
            break;
      }
    }

    const renderLoader = (percentages) => (
      <div style={{ width: '240px' }}>
          <ProgressBar progress={Math.round(percentages)} />
      </div>
    );

    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4 className="">
                {title}
              </h4>
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            {permission ?
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">             
              <Viewer 
                fileUrl={`https://pdf.renatogonda.com.br/livros/${titleparams}.pdf`} 
                renderError={renderError}
                renderLoader={renderLoader}
              />
            </Worker>             
            :
            <Formulario onSubmit={handleCreatePermission} ref={formPermission} initialData={usuario}>   
              <p>COMPLETE AS INFORMAÇÕES ABAIXO PARA LER O LIVRO</p>
              <Input name="name" placeholder="seu nome" label="Nome"/>
              <Input name="email" placeholder="seu email" label="E-mail"/>
              <InputMask name="whatsapp" mask="(99)9.9999-9999" label="WhatsApp"  placeholder="seu whatsapp"/>
              <p></p>
              <button disabled={loading} type="submit" className="btn btn-success" 
              style={{width:'100%', fontWeight:'bold', color:'#fff'}}>
               <FaBook/> LER O LIVRO 
              </button>
            </Formulario>          
            }
            {msg}
          </Modal.Body>
        </Modal>
      );
    
  }



  return (
    <>
    <NavBar />
      <ModalVertical show={modalShow} onHide={() => {setModalShow(false); setPermission(false)}}/>
        <Container className="book-page">
          <Row>
            <Col sm={9}>
            {loading ? <h1>Aguarde...</h1> : book?.map((book, index) => (
            <Row  key={index}>
              <Col sm={6} className="book-img">
              <img src={book?.capa_img} alt={book?.title} /> 
              </Col>
              <Col sm={6}>     
                <div className="box-book">   
                  <h2 className="title-book-page">{book?.title}</h2>
                  <p><strong>Release</strong><br/>{book?.release}</p>
                  <p style={{fontWeight:'bold'}}>{price(book?.preco_venda)}</p>
                 
                  <div className="btn-acao">  
                  {productsCart.find((item) => item.id === book.id)?.qtd ?
                    <button className="btn btn-danger" disabled onClick={() => removeProductToCart(book.id)}>
                      Remover
                  </button> : ""}
                  <button className="btn btn-warning" onClick={()=>{handleAbrirModal()}}>LER O LIVRO</button>
                  {book.stock > 1 ? 
                  <button className="btn btn-success" onClick={() => 
                    {addProducToCart(book.id, book.capa_img, book.title, book.preco_venda, book.titleparams, book.category);                     
                    handleGoToCart(book.category);}}>                      
                    <strong>COMPRAR</strong> 
                      <strong style={{fontWeight:'bold', marginLeft:10}}>
                      {productsCart.find((item) => item.id === book.id)?.qtd ? productsCart.find((item) => item.id === book.id)?.qtd : ''}
                      </strong>
                  </button>
                  :
                  <button className="btn btn-danger"><strong>ESGOTADO</strong></button>}
                  </div>      
                </div>  
              </Col>  
              <hr/>
              <Col sm={12}>
              <p><strong>Histórico</strong><br/> {book?.historico}</p>  
              </Col>
              <hr/>
              <Col sm={12}>
              <p><strong>préTEXTOgonda</strong><br/> {book?.pretexto}</p>  
              </Col>
            </Row>))} 
            </Col>            
            <Col sm={3}>
              {loading ? <h2>Carregando...</h2> :
              books?.map((book, index) => {
                return <MiniCard key={index} product={book}/>
              })}
            </Col>
          </Row>

          <hr/>

          {admin &&
            <>
            <h3 style={{marginTop:30, textAlign: 'center'}}>AQUI VOCÊ ATUALIZA O LIVRO!</h3>
              <hr/>
                <Formulario onSubmit={handleSubmit} ref={formRef} initialData={book}>
                  <Row>
                    <Col sm={12}>
                      <DropzoneInput name="files"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Input label="Título: (Ex.: titulodolivro)" name="titleparams"/> 
                    </Col>
                    <Col sm={6}>
                      <Input label="Título Normal" name="title"/> 
                    </Col>               
                  </Row> 
                  <hr/>
                  <Row>
                  <Col sm={4}>
                      <Input label="Histórico" name="historico"/> 
                    </Col>
                    <Col sm={4}>
                      <Input label="Release" name="release"/>
                    </Col>
                    <Col sm={4}>
                      <Input label="PréTexto" name="pretexto"/> 
                    </Col>             
                  </Row> 
                  <hr/>
                  <Row>
                    <Col sm={1}>
                      <Input label="Preço" name="preco_venda"/>
                    </Col>
                    <Col sm={1}>
                      <Input label="Estoque" name="stock"/>
                    </Col>
                    <Col sm={2}>
                      <Input label="Categoria" name="category"/>
                    </Col>
                    <Col sm={2}>
                      <Input label="Autor" name="author"/> 
                    </Col>
                    <Col sm={6}>
                      <Input label="URL DA CAPA em JPG" name="capa_img"/> 
                    </Col>
                  </Row> 
                  <Row>
                    <Col sm={12} style={{textAlign: 'center', padding:10,}} className="font-face-bs">
                      {msgsucess}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <button type="submit" className="btn btn-success" style={{width:'100%', fontWeight:'bold'}}>
                        Atualizar Livro
                      </button>
                    </Col>
                  </Row>
                </Formulario>
              <hr/>
              <button className="btn btn-danger" style={{fontWeight:'bold'}} onClick={() =>{handleRemoveBook(codigo)}}>Remover Livro</button>
            </> 
          }
        </Container>
    </> 
  )
};