import React, { useContext, useState, useEffect } from "react"
import { auth, database } from '../services/firebase';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  
  const [currentUser, setCurrentUser] = useState()
  const [usuario, setUsuario] = useState('');
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])


  useEffect(() => {
    currentUser ? 
      database.ref(`users/${currentUser.uid}`).on('value', snap => {
        setUsuario(snap.val());
      })      
    : setUsuario('');
    
  }, [currentUser]);

  useEffect(() => {
    usuario.superUser === true ? setAdmin(true) : setAdmin(false)
  },[usuario])


  const value = {
    usuario,
    admin,
    loading,
    setLoading,
    
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}