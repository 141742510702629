import React, {} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { NavBar } from '../../components/Navbar';

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export function AboutPage() {
  const urlbio = "https://pdf.renatogonda.com.br/livros/bio.pdf"; 
  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col sm={12}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <Viewer fileUrl={urlbio}/>
            </Worker>
          </Col>
        </Row>
      </Container>
    </>
  );
}