import React, {useState, useEffect, useRef } from "react";
import { Container, Row, Col }from 'react-bootstrap';
import { useNavigate, useLocation}  from 'react-router-dom';
import { useCart } from "../../context/CartContext";
import { database } from '../../services/firebase';
import { useAuth } from "../../context/AuthContext";

import { FaArrowRight, FaUserTie, FaTrashAlt } from "react-icons/fa";

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import Input from '../../components/Form/input';
import InputMask from '../../components/Form/inputMask';

import uuid from 'react-uuid';

export function Cart() {
  const navigate = useNavigate();
  const location = useLocation();
  const { usuario } = useAuth();
  const formAddressRef = useRef(null);
  const [numerodopedido, setNumerodopedido] = useState(0)
  const {productsCart =[], removeProductToCart, addProducToCart, clearCart, totalporlivro, frete, valortotal, prazo } = useCart();

  useEffect(() => {
   // console.log(location?.state?.category);
  }, [location]);

  useEffect(() => {  
    const pedidoRef = database.ref(`/pedido/`);
      pedidoRef.on('value', (snapshot) => {      
        const pedido = snapshot.val();
        setNumerodopedido(pedido);
      });
    return () => {
      pedidoRef.off('value');
    }
  }, []);
  
  useEffect(() => {
    let t = setTimeout(() => {
      if(productsCart.length < 1){
        navigate("/livros");
      }
    }, 1000);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [productsCart]);


  //Descontos

// 10% – acima de R$100,00
// 15% – acima de R$300,00
// 20% – acima de R$500,00

// Desconto em parcelas

// 5% – acima de R$100,00
// 10% – acima de R$300,00
// 15% – acima de R$500,00

  useEffect(() =>{

  },[])


  function handleGoToProduct(title, id){
    navigate(`/livros/${title}`, {
      state: {
        code: id
      }
    })
  }

  function handleCreateAccount(){
    navigate(`/createaccount`)
  }

  function handleGoToLogin(){
    navigate(`/login`)
  }

  function handleGoToContato(){
    navigate(`/contatos`)
  }

  function handleConverter(valor){
    const converter = valor?.toLocaleString('pt-br', {minimumFractionDigits: 2}) || "";
    return converter;
   }

  async function handleCreateOrder(data, {reset}) {
    const novoid = uuid();
    const hoje = new Intl.DateTimeFormat('pt-BR').format(new Date());

    const usuarioRef = database.ref(`users/${usuario?.uid}`);
    const orderRef = database.ref(`orders/${novoid}`);
    const pedidoRef = database.ref(`/pedido/`);

    try{
      const schema = Yup.object().shape({
      name: Yup.string()
      .required("* "),
      email: Yup.string().email()
      .required("* "),
      telefone: Yup.string()
      .required("* "),
      whatsapp: Yup.string()
      .required("* "),
      cpf: Yup.string()
      .required("* "),

      address: Yup.object().shape({
        cep: Yup.string().min(8, "digite o CEP")
        .required("*"),
        logradouro: Yup.string()
        .required("*"),
        numero: Yup.string()
        .required("*"),   
        bairro: Yup.string()
        .required("*"),
        cidade: Yup.string()
        .required("*"),
        estado: Yup.string().length(2, 'Ex.: SP')
        .required("*")
      })
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const { name, email, telefone, whatsapp, cpf, address={}} = data;
    // console.log(data);

    await usuarioRef.update({
      name,
      telefone,
      whatsapp,
      cpf,
      address
    });

    //console.log('subiu endereço user')

    await orderRef.set({
      pedido: 1 + numerodopedido,
      date:hoje,
      status:"Pendente",
      id:novoid,
      userId:usuario.uid,
      address,
      itens:productsCart,
      frete:frete,
      prazo,
      total:totalporlivro,
      valortotal,
      name,
      email,
      telefone,
      whatsapp,
      cpf,
    }); 

    await pedidoRef.set(numerodopedido + 1);

    clearCart();
  
    formAddressRef.current.setErrors({});
    alert('Seu pedido foi realizado com sucesso!');
  
    reset();  

    }catch(err){
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formAddressRef.current.setErrors(errorMessages);
        console.log(`erros: ${JSON.stringify(errorMessages)}`)
      }
    }
  }

  return (
    <Container>    
      {productsCart.length > 0 ?
      <>   
        <h2 style={{textAlign: 'center', marginTop:40}}>Meu Carrinho</h2>
        <p></p>
        <hr/>
        <Row className="" style={{marginBottom:10, fontWeight:'bold'}}>
            <Col sm={1}>           
            </Col>
            <Col sm={3}>
              Título
            </Col>
            <Col sm={2} style={{textAlign: 'center'}}>
              Quantidade
            </Col>
            <Col sm={2} style={{textAlign: 'center'}}>
              Valor Unid.
            </Col>
            <Col sm={2}>
              Total
            </Col>        
        </Row>

      {productsCart?.map((product) => 
        <Row key={product.id} className="cart_container">
          <Col sm={1}>
            <img style={{width: 50, height: 50, cursor: 'pointer'}} src={product.capa_img} alt="" 
             onClick={()=>{handleGoToProduct(product.titleparams, product.id)}}>              
            </img>
          </Col>
          <Col sm={3}>
            <p style={{cursor: 'pointer'}} onClick={()=>{handleGoToProduct(product.titleparams, product.id)}}>{product.title}</p>
          </Col>
          <Col sm={2} className="actions-cart-item">

            <button style={{width: 50,}} className="btn btn-secondary center" 
            onClick={() => removeProductToCart(product.id)}> -
            </button>

            <button style={{border:'1px solid #ddd', width:50, height:40, marginBottom:5, borderRadius:10, marginRight:3}}>{product.qtd}</button>

            <button style={{width: 50,}} className="btn btn-secondary center" 
            onClick={() => addProducToCart(product.id)}> +
            </button>

          </Col>
          <Col sm={2} style={{textAlign: 'center'}}>
           <strong>R$ {handleConverter(product.preco_venda)}</strong>
          </Col>
          <Col sm={3}>
            <strong>R$ {handleConverter(product.preco_venda * product.qtd)}</strong>
          </Col>
          <Col sm={1}>
            <button className="btn btn-danger" onClick={() => removeProductToCart(product.id)}><FaTrashAlt/></button>
          </Col>
        </Row>
      )}
      {usuario && !usuario.superUser &&
      <Row style={{border:'1px solid #ddd', padding:30, borderRadius:10}}>
          <Col sm={9} style={{borderRight:'1px solid #ddd',}}>          
            <Form onSubmit={handleCreateOrder}className="form" ref={formAddressRef} initialData={usuario}>
              <Row>
                  <Col sm={6}>
                    <Input label="Nome" name="name"/>
                  </Col>
                  <Col sm={6}>
                    <Input label="E-mail" name="email"/>
                  </Col>
              </Row>
              <Row>
                <Col sm={4}>           
                    <InputMask name="telefone" mask="(99) 9999-9999" label="Telefone"/>
                  </Col>
                  <Col sm={4}>
                    <InputMask name="whatsapp" mask="(99) 9.9999-9999" label="WhatsApp"/>
                  </Col>
                  <Col sm={4}>
                    {/* <Input label="CPF" name="cpf"/> */}
                    <InputMask name="cpf" mask="999.999.999-99" label="CPF"/>
                  </Col>
              </Row> 

              <Row>
                  <Col sm={4}>
                    <Scope path="address">
                      <Input name="logradouro" label="Endereço"/>
                    </Scope>
                  </Col> 
                  <Col sm={2}>  
                    <Scope path="address">
                      <Input label="Nº" name="numero"/>
                    </Scope>                  
                  </Col>
                  <Col sm={3}>
                    <Scope path="address">
                      <Input label="Complemento" name="complemento"/>
                    </Scope>
                  </Col>
                  <Col sm={3}>
                    <Scope path="address">
                      <Input label="Bairro" name="bairro"/>
                    </Scope>
                  </Col>
              </Row>
              <Row>
                  <Col sm={3}>
                    <Scope path="address">
                      <Input label="Cidade" name="cidade"/>
                    </Scope>
                  </Col>
                  <Col sm={2}>
                    <Scope path="address">              
                      <Input label="(UF)" name="estado" />
                    </Scope>
                  </Col>
                  <Col sm={4}>  
                    <Scope path="address">
                      <Input label="Infos" name="infos" />
                    </Scope>
                  </Col>
                  <Col sm={3}>   
                    <Scope path="address">
                      <InputMask label="CEP" name="cep" mask="99999-999"/>
                    </Scope>                    
                  </Col>
              </Row>
              <div className="campoobrigatorio">
                <p style={{color:"#f00"}}>* <i style={{color:"#222"}}>Campo obrigatório</i></p>
              </div>

                <button type="submit" className="btn btn-success" 
                  style={{width: '100%', color:'#fff', fontWeight:'bold', marginTop:20}}>
                    {usuario?.email ?  "Finalizar Compra" : "Fazer Cadastro" }
                </button>          
            </Form>
          
          </Col>
          <Col sm={3} style={{paddingLeft:20}}>
            <h3>Resumo da Compra</h3>
            <h4> <hr/></h4>
            <p><strong>Total:</strong> R$ {handleConverter(totalporlivro)}</p>
            {frete === 0 ? <p onClick={() => handleGoToContato} style={{cursor: 'pointer'}}> Consulte-nos</p> : <p><strong>Envio: </strong><span>R$ {handleConverter(frete)}</span></p>}
            <p><strong>Prazo:</strong> <i style={{fontSize:12}} >até {prazo} dias úteis</i></p>
            <p><strong>Sub-Total:</strong> <span style={{fontSize:18}}>R$: {handleConverter(valortotal)}</span></p>            
          </Col>
        <Row>
        </Row>
        {/* {usuario && <button className="btn btn-success" onClick={() => handleCreateOrder()}>FINALIZAR COMPRA</button>} */}
      </Row>}

      </> : 

      <Row>
        <Col sm={12} style={{textAlign: 'center'}}>
          SEU CARRINHO ESTÁ VAZIO
        </Col>
      </Row>    

     }

      <Row style={{marginTop:30, fontWeight:'bold'}}>
        <Col sm={2}></Col>
        <Col sm={4} style={{paddingRight:10, paddingLeft:0}}>
        {!usuario &&  <button style={{fontWeight:'bold'}} className="btn btn-secondary" onClick={handleGoToLogin}>ENTRAR <FaArrowRight/></button> }
     
        </Col>
        <Col sm={4} style={{padding:0}}>
        {!usuario && <button style={{fontWeight:'bold'}} className="btn btn-secondary" onClick={handleCreateAccount}><FaUserTie/> CRIAR CONTA</button> }
        </Col>
        <Col sm={2}></Col>
      </Row>

      

    </Container>
  );
}
