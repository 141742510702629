import React from 'react'
import { Container, Row, Col }from 'react-bootstrap';
import { NavBar } from '../../components/Navbar'

import { Orders } from '../../components/Orders'

export function OrdersPage() {
  return (
    <>
        <NavBar />         
        <Container className="add-book-page">
          <Row>
            <Col sm={12}>
              <Orders/>
            </Col>        
          </Row>
        </Container>
    </>  
  )
}