import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap/';

import { useCart } from "../../context/CartContext";
import { FaArrowRight } from "react-icons/fa";

import { Form } from '@unform/web';
import Input from '../../components/Form/input';

import { useAuth } from "../../context/AuthContext";
import { NavBar } from '../../components/Navbar';

export function Autenticacao(){
  const navigate = useNavigate();
  const formLoginRef = useRef(null);

  const {productsCart =[] } = useCart();

  const { login, usuario } = useAuth();
  const [ loading, setLoading ] = useState(false)
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');

  async function handleLogin() {
    try{
      setError('')
      setLoading(true)
      await login(email, password)
      setEmail("");
      setPassword("")
      setLoading(false)
    } catch {
      setError(`Complete os campos corretamente!`)
      setLoading(false)
    }   
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(usuario){
        navigate("/livros");
      }
      if(usuario && productsCart.length > 0){
        navigate("/cart");
      }
    }, 100);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [usuario]);


  function handleGoToCreateAccount(){
    navigate("/createaccount");
  }


  return(
    <>
    <NavBar />
      <Container>
          <Row>
            <Col sm={4}></Col>
            <Col sm={4} className="formlogin font-face-bs" style={{border:'1px solid #ddd', padding:30, borderRadius:10, marginTop:50}}>
              <Row style={{padding:50, textAlign: 'center', borderRadius:10}}>  
                <Col sm={12}>          
                  <Col sm={12} style={{padding:10, textAlign: 'center'}}>
                    {/* Faça seu Login */}
                  </Col>
                  <Form onSubmit={handleLogin} ref={formLoginRef}>   
                    <Input
                      type="email"
                      name="email"
                      placeholder="digite seu email"
                      onChange={event => setEmail(event.target.value)}
                      value={email}
                    />            
                    <Input
                      name="password"
                      type="password"
                      placeholder="digite sua senha"
                      onChange={event => setPassword(event.target.value)}
                      value={password}
                    />
                    <button 
                    disabled={loading} type="submit" className="btn btn-success" 
                    style={{width:'100%', fontWeight:'bold', color:'#fff'}}>
                      ENTRAR <FaArrowRight/> 
                    </button>
                  </Form>
                  <p>{error}</p>           
                  </Col>
              </Row>
              <Row>
                <Col sm={12} style={{padding:20, textAlign: 'center'}}>
                <p style={{cursor: 'pointer'}} onClick={() => handleGoToCreateAccount()}>Não tem cadastro? <strong>CRIAR CONTA</strong></p>
                </Col>
              </Row>
              
            </Col>
            <Col sm={4}></Col>
          </Row>
    </Container>
    
  </>   
  );
}
