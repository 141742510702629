import React, { useContext, useState, useEffect } from "react";

const CartContext = React.createContext()

export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  const [productsCart, setProductsCart] = useState(
    JSON.parse(localStorage.getItem('products_cart')) || []
  );

  const [totalporlivro, setTotalporLivro] = useState(0)
  const [itensnocarrinho, setItensnoCarrinho] = useState(0)
  const [frete, setFrete] = useState(0)
  const [prazo] = useState(9)
  const [valortotal, setValortotal] = useState(0)

  function addProducToCart(id, img, title, preco_venda, titleparams, category) {
    const copyProductsCart = [...productsCart];
    const item = copyProductsCart.find((product) => product.id === id);
      if (!item) {
        copyProductsCart.push({ id, qtd: 1, capa_img:img, title:title, preco_venda:preco_venda, titleparams:titleparams, category:category});
      } else {
        item.qtd = item.qtd + 1;
      }
      setProductsCart(copyProductsCart);
      localStorage.setItem('products_cart', JSON.stringify(copyProductsCart));
  }

  function removeProductToCart(id) {
    const copyProductsCart = [...productsCart];
    const item = copyProductsCart.find((product) => product.id === id);
      if (item && item.qtd > 1) {
        item.qtd = item.qtd - 1;
        setProductsCart(copyProductsCart);
        localStorage.setItem('products_cart', JSON.stringify(copyProductsCart));
      } else {
        const arrayFiltered = copyProductsCart.filter((product) => product.id !== id);
        setProductsCart(arrayFiltered);
        localStorage.setItem('products_cart', JSON.stringify(arrayFiltered));
      }
  }

  useEffect(() => {
    let total = 0;
    productsCart.map(item => (
      total += parseFloat(item.preco_venda * item.qtd)
    ));
    setTotalporLivro(total);

    const totaldelivros = productsCart.reduce((prevVal, elem) => prevVal + elem.qtd, 0);

    setItensnoCarrinho(totaldelivros);
    localStorage.setItem('total', JSON.stringify(total));
  },[productsCart]);


  useEffect(() => {
    itensnocarrinho === 1 && setFrete(10);
    itensnocarrinho === 2 && setFrete(15);
    itensnocarrinho > 2 && itensnocarrinho <= 5  && setFrete(25);
    itensnocarrinho >= 6 && itensnocarrinho <= 10 && setFrete(45);    
    itensnocarrinho === 11 && setFrete(50);
    itensnocarrinho >= 12 && setFrete(0)
  },[itensnocarrinho])


  useEffect(() => {
    const soma = frete + totalporlivro
    setValortotal(soma);
    // eslint-disable-next-line
  },[removeProductToCart, addProducToCart, productsCart])


  function clearCart() {    
    setProductsCart([]);
    localStorage.clear();
    setTotalporLivro(0);
  }
  
  const value = { 
    totalporlivro,
    frete,
    prazo,
    valortotal,
    itensnocarrinho,
    productsCart,
    addProducToCart,
    removeProductToCart,
    clearCart,
    }
    
  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}