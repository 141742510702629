import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import {Container, Nav, Navbar, 
  // NavDropdown, Form, Button 
} from 'react-bootstrap/';
import  * as Icons from 'react-bootstrap-icons';

import { FaRegPlusSquare, FaShoppingCart} from "react-icons/fa";

import logo  from '../../assets/logo_white.png';

export function NavBar() {
  
  const navigate = useNavigate();
  const {logout, usuario} = useAuth();
  const {productsCart} = useCart();
  const [error, setError] = useState('');

  const handleHome = () => {
    navigate('/')
  }
  const handleBooks = () => {
    navigate('/livros')
  }
  const handleAbout = () => {
    navigate('/about')
  }
  // const handleCorreraEgonda = () => {
  //   navigate('/correragonda')
  // }
  const handleContacts = () => {
    navigate('/contact')
  }
  const handleCart = () => {
    navigate('/cart')
  }
  const handleAddBookPage = () => {
    navigate('/addbook')
  }
  const handleLogin = () => {
    navigate('/login')
  }

  const handleOrders = () => {
    navigate('/orders')
  }

  async function handleLogoutSubmit() {
    try {
      setError('')
      await logout();
      } catch(error) {
      setError(`Falha no Logout`);
    }
  }

  return (
    <div  className="header">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container className="fntrbt">
        <Navbar.Brand onClick={handleHome} className="linhaheader">
         <img src={logo} alt="logo-renato-gonda"  width="200" onClick={handleHome} style={{cursor: 'pointer'}}/> 
        </Navbar.Brand> 

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse  id="responsive-navbar-nav" >
            <Nav className="me-auto nav_bar_items" >
              {/* <Nav.Link onClick={handleHome} className="navItem">Home</Nav.Link>  
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link> */}
              <Nav.Link onClick={handleAbout} className="navItem">Renato Gonda</Nav.Link>
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link>
              {/* <Nav.Link onClick={handleCorreraEgonda} className="navItem">Correra&Gonda</Nav.Link>     
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link> */}
              <Nav.Link onClick={handleBooks} className="navItem">Livros</Nav.Link>     
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link>
              <Nav.Link onClick={handleContacts} className="navItem">Contato</Nav.Link>
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link>              
              {usuario &&  <Nav.Link onClick={handleOrders} className="navItem">Pedidos</Nav.Link>}
              <Nav.Link  className="navItem itemmobile"> | </Nav.Link>
              <Nav.Link onClick={handleCart} className="navItem">
              {productsCart?.length ? 
              <>
                <FaShoppingCart style={{color: '#080'}}/>
                <span style={{backgroundColor:'#080', borderRadius:50, fontSize:9, padding:5, color:"#222", marginLeft:5}}>
                  {productsCart?.length}
                </span>              
              </>
               : <FaShoppingCart style={{color: '#999'}}/>}
              </Nav.Link>


              
              {!usuario ? <Nav.Link onClick={handleLogin} className="navItem Items"><Icons.PersonCircle style={{color:'#f00', fontSize:22}} className="icons"/></Nav.Link> :
                <Nav.Link onClick={() => handleLogoutSubmit()} className="navItem Items"><Icons.PersonCircle style={{color:'#080', fontSize:22}}className="icons" />{error? error : ""}</Nav.Link>}
              {usuario?.superUser && <>                
                <Nav.Link className="navItem itemmobile"> | </Nav.Link>
                <Nav.Link onClick={handleAddBookPage} className="navItem Items"><FaRegPlusSquare /> Livro</Nav.Link>
              </>}
            </Nav>
          </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
