import React, { useState, useRef } from 'react'
import { Container, Row, Col }from 'react-bootstrap'
import { useAuth } from "../../context/AuthContext";
import { NavBar } from '../../components/Navbar'
import { useNavigate } from 'react-router-dom'

import { Form as Formulario } from '@unform/web';
import Input from '../../components/Form/input';
import DropzoneInput from "../../components/Form/dropzone";
import uuid from 'react-uuid';
import * as Yup from 'yup';

import api from '../../services/api';
import {storage} from '../../services/firebase';

export function AddBookPage() {
  const { usuario } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [msgsucess, setMsgSucess] = useState(false);
  const [visible, setVisible] = useState(true);

  async function handleSubmit(data, {reset}) {

    const novoid = uuid();
    const newImage = storage.ref(`capas/${novoid}`);

    try{
      const schema = Yup.object().shape({
      titleparams: Yup.string()
      .required("* obrigatório"),
      title: Yup.string()
      .required("* obrigatório"),
      historico: Yup.string()
      .required("* obrigatório"),
      release: Yup.string()
      .required("* obrigatório"),
      pretexto: Yup.string()
      .required("* obrigatório"),
      preco_venda: Yup.string()
      .required("* obrigatório"),
      stock: Yup.string()
      .required("* obrigatório"),
      category: Yup.string()
      .required("* obrigatório"),
      author: Yup.string()
      .required("* obrigatório"),
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const { 
      titleparams,
      title,
      historico,
      release,
      pretexto,
      preco_venda,
      author,
      capa_img,
      stock,
      category,
      files} = data;

      setMsgSucess('')
      setLoading(true)
  
      await api.post(`/livros/`, {
        titleparams,
        title,
        historico,
        release,
        pretexto,
        preco_venda,
        author,
        capa_img,
        stock, 
        category
      });

      formRef.current.setErrors({});

      if(files[0]){
        setLoading(true);
        await newImage.put(files[0]);
        await newImage.getDownloadURL().then((url) => {    
          api.put(`/livros/${novoid}`,{
            capa_img:url
          })
        });
        setLoading(false);
      }

      setMsgSucess('Livro Cadastrado com Sucesso!')
      setLoading(false);

      setTimeout(() => {
        setVisible(false);
        setMsgSucess('')
        setLoading(true);
        navigate("/livros");
      },5000)

    reset();

    }catch(err){
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages);
      }
    }
 
  }

  // const openInNewTab = url => {
  //   // 👇️ setting target to _blank with window.open
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };


  return (
    <>
      <NavBar />     

      <Container className="home-page">
         
          <Row>
            <Col sm={12}>
             {loading? <p>Aguarde...</p> : <h1>Cadastrar um Livro</h1>}
              Olá {usuario?.email}
             <hr/>
            </Col>        
          </Row>

        {usuario.superUser && visible &&
          <Formulario onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col sm={12}>
                  <DropzoneInput name="files"/>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Input label="Título: (ex: titulodolivrotudojunto)*" name="titleparams" placeholder="(ex: titulodolivro)*"/> 
                </Col>
                <Col sm={3}>
                  <Input label="Título Normal" name="title"/> 
                </Col>        
                <Col sm={3}>
                  <Input label="Categoria" name="category" placeholder="cat_renato_gonda"/> 
                </Col>       
              </Row> 
                <Row>
                <Col sm={4}>
                    <Input label="Histórico" name="historico"/> 
                  </Col>
                  <Col sm={4}>
                    <Input label="Release" name="release"/>
                  </Col>
                  <Col sm={4}>
                    <Input label="PréTexto" name="pretexto"/> 
                  </Col>            
                </Row> 
                <Row>
                  <Col sm={2}>
                    <Input label="Preço" name="preco_venda" placeholder="50"/>
                  </Col>
                  <Col sm={2}>
                    <Input label="Estoque" name="stock"/>
                  </Col>
                  <Col sm={4}>
                    <Input label="Autor" name="author"/> 
                  </Col>
                  <Col sm={4}>
                    <Input label="URL DA CAPA em JPG" name="capa_img" placeholder="adicione no quadro azul ou digite #"/> 
                  </Col>
                </Row>                 
                <Row>
                  <Col sm={12}>
                    <button type="submit" className="btn btn-success" style={{width:'100%', fontWeight:'bold', marginTop:20}}>
                      Cadastrar Livro
                    </button>
                  </Col>
                </Row>
          </Formulario>}
          <Row>
            <Col sm={12}>
              {msgsucess && <p>{msgsucess}</p>}
            </Col>        
          </Row>
        </Container>
    </>  
  )
}